import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useLocation } from 'react-router-dom';

const EChart = ({labelLegend, labelProp, successProp }: any) => {

  const { pathname } = useLocation();
  const staticsPath = pathname.includes("statistics-count");
  const chartRef = useRef<HTMLDivElement | null>(null);
  const legendSucces = 'Vehicles'

  useEffect(() => {
    if (chartRef.current) {
      // Initialize ECharts instance
      const chart = echarts.init(chartRef.current);

      // Define chart options
      const options: echarts.EChartOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            }
          },
        xAxis: {
          type: 'category',
          data: labelProp,
          axisPointer: {
            type: 'shadow',
          },
        },
        yAxis: {
          type: 'value',
        },
        // legend: {
        //   data: ['Growth', legendSucces],
        //   itemGap: 5
        // },
        series: [
          {
            name: legendSucces,
            type: 'bar',
            data: successProp,
            itemStyle: {
              color: '#a9df96'
            }
          },
        ],
        // Enable mix-zoom-on-value feature
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0],
            filterMode: 'none',
            start: 0,
            end: 100,
          },
          {
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'none',
            start: 0,
            end: 100,
          },
        ],
      };

      // Set chart options and render the chart
      chart.setOption(options, true); // Second parameter "true" indicates not to merge with the previous options

      // Clean up the chart instance when the component unmounts
      return () => {
        chart.dispose();
      };
    }
  }, [labelLegend, labelProp, successProp]);

  return <div ref={chartRef} style={{ width: '100%', height: staticsPath ? '600px' : '320px' }} />;
};

export default EChart;