import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import { useEffect, useState } from "react";
import TextAreaForm from "../forms/textAreaForm";
import { useForm } from "react-hook-form";

const LogResultTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
  setOpen,
  setCurrentImg
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    rfid: string;
  }>({
    reValidateMode: "onChange",
    defaultValues: {
      rfid: "",
    },
  });

  useEffect(() => {
    reset({
      rfid: data != undefined ? data.rfid : ""
    })
  })

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "event",
      headerName: "Event Description",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <img
          height={'50px'}
          width={'50px'}
          style={{ cursor: 'pointer'}}
          src= {params.row.image}
          onClick={()=> {
            setCurrentImg(params.row.image)
            setOpen(true)
          }}/>
        );
      },
    },
  ];

  const tempData = data != undefined ? data.events : []

  // REGION: INIT ROWS
  const rows = tempData.map((row: any, index: any) => ({
    id: index,
    timestamp: row.timestamp,
    event: row.description,
    image: row.image,
    position: row.position,
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "400px", marginY:'24px' }}>          
          
        <Typography variant="h3" sx={{ marginBottom: '24px' }}>
          Vehicle Detail
        </Typography>

        <Grid container sx={{ marginBottom: "20px" }}>
          <Grid item xs={4}>Plate No.</Grid>
          <Grid item xs={8}>{data != undefined ? data.plateNumber : ""}</Grid>
        </Grid>

        <Grid container sx={{ marginBottom: "20px" }}>
          <Grid item xs={4}>Plate Type</Grid>
          <Grid item xs={8}>{data != undefined ? data.plateType : ""}</Grid>
        </Grid>

        <Grid container sx={{ marginBottom: "20px" }}>
          <Grid item xs={4}>Vehicle Type</Grid>
          <Grid item xs={8}>{data != undefined ? data.vehicleType : ""}</Grid>
        </Grid>

        <Grid container sx={{ marginBottom: "20px" }}>
          <Grid item xs={4}>Vehicle Color</Grid>
          <Grid item xs={8}>{data != undefined ? data.vehicleColor : ""}</Grid>
        </Grid>

        <TextAreaForm
          name='rfid'
          label={'RFID ID'}
          errors={errors}
          control={control}
          placeholder="RFID ID"
          maxLength={100}
          isLoading={false}
          key={1} 
          defaultValue={''}
          disabled
        />

        <Typography variant="h3" sx={{ marginBottom: '24px' }}>
          Timestamp / Event Description
        </Typography>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />

      </Box>
      {/* <PaginationControl params={params} setParams={setParams} total={data?.data?.total_records} /> */}
    </>
  );
};

export default LogResultTable;
