// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const trafficEventLogsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTrafficEventLogsList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/trafficlogs`,
                    params: params
                }
            },
            providesTags:['GetTrafficEventLogsList'],
        })
  })
})

export const {
useGetTrafficEventLogsListQuery
} = trafficEventLogsApiSlice;