import IRoutes from "../interfaces/IRoutes";
import TrafficEventLogs from "../pages/trafficEventLogs/TrafficEventLogs";

const TrafficEventsRoute: Array<IRoutes> = [
    {
        element: <TrafficEventLogs />,
        path: "/traffic-event-log",
    }
];

export { TrafficEventsRoute };
