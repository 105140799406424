import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import userReducer from "./feature/user";
// import areaTracking from "./feature/areaTracking";
import { apiSlice } from "./feature/api/apiSlice";
import { areaTrackingReducer } from "./feature/areaTrackingReducer";
import { areaTrackingApiSlice } from "./feature/service/areaTrackingApiSlice";
import { userReducer } from "./feature/userReducer";
import { vehicleTrackingReducer } from "./feature/vehicleTrackingReducer";
import { trafficEventLogsReducer } from "./feature/trafficEventLogsReducer";

// export const store = configureStore({
//   reducer: {
//     user: userReducer,
//     areaTracking: areaTracking
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

export const reducer = combineReducers({
  areaTracking: areaTrackingReducer,
  user: userReducer,
  vehicleTracking: vehicleTrackingReducer,
  trafficEventLogs: trafficEventLogsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export type RootState = ReturnType<typeof reducer>;


export const createStore = () =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      // if (isDevelopment()) {
      //   return getDefaultMiddleware()
      //     .concat(apiSlice.middleware)
      //     .concat(logger);
      // }
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
