import IRoutes from "../interfaces/IRoutes";
import VehicleTracking from "../pages/vehicleTracking/VehicleTracking";

const VehicleTrackingRoute: Array<IRoutes> = [
    {
        element: <VehicleTracking />,
        path: "/vehicle-tracking",
    }
];

export { VehicleTrackingRoute };
