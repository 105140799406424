export const dataAreaTracking = [
    {
        time_range: "Daily",
        total_vehicle: 100,
        areas: [
            {
                label: "All Area",
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "50",
                        rfid: "50"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "15",
                        rfid: "15"
                    },
                    {
                        type: "Government (Red)",
                        count: "20",
                        rfid: "20"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "10",
                        rfid: "10"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "31",
                        rfid: "31"
                    },
                    {
                        type: "Truk",
                        count: "18",
                        rfid: "18"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "26",
                        rfid: "26"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "4",
                        rfid: "4"
                    },
                    {
                        type: "Motorcycle",
                        count: "21",
                        rfid: "21"
                    },
                ]
            },
            {
                label: "Area 1",
                vehicle_area: 10,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "2",
                        rfid: "2"
                    },
                    {
                        type: "Government (Red)",
                        count: "2",
                        rfid: "2"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "1",
                        rfid: "1"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "2",
                        rfid: "2"
                    },
                    {
                        type: "Truk",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "1",
                        rfid: "1"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "1",
                        rfid: "1"
                    },
                    {
                        type: "Motorcycle",
                        count: "1",
                        rfid: "1"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.913545, lng: 104.088710 },
                    { lat: 0.926332, lng: 104.092229 },
                    { lat: 0.935601, lng: 104.084848 },
                    { lat: 0.936871, lng: 104.080300 },
                    { lat: 0.944932, lng: 104.076686 },
                    { lat: 0.951642, lng: 104.080974 },
                    { lat: 0.962441, lng: 104.076896 },
                    { lat: 0.956270, lng: 104.091333 },
                    { lat: 0.960237, lng: 104.096953 },
                    { lat: 0.964093, lng: 104.104998 },
                    { lat: 0.959355, lng: 104.110178 },
                    { lat: 0.964534, lng: 104.120758 },
                    { lat: 0.956270, lng: 104.122852 },
                    { lat: 0.954066, lng: 104.129354 },
                    { lat: 0.941615, lng: 104.128803 },
                ],
                marker_position: { lat: 0.936755, lng: 104.097937 },
                info_position: { lat: 0.951193, lng: 104.097455 },
            },
            {
                label: "Area 2",
                vehicle_area: 20,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "10",
                        rfid: "10"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Government (Red)",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "1",
                        rfid: "1"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "1",
                        rfid: "1"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Truk",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "10",
                        rfid: "10"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.866869, lng: 104.090014 },
                    { lat: 0.874837, lng: 104.108885 },
                    { lat: 0.866977, lng: 104.115091 },
                    { lat: 0.859746, lng: 104.121179 },
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.929238, lng: 104.157913 },
                    { lat: 0.939646, lng: 104.145293 },
                    { lat: 0.946291, lng: 104.136748 },
                ],
                marker_position: { lat: 0.903664, lng: 104.127249 },
                info_position: { lat: 0.919101, lng: 104.126707 },
            },
            {
                label: "Area 3",
                vehicle_area: 35,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "15",
                        rfid: "15"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Government (Red)",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "7",
                        rfid: "7"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "10",
                        rfid: "10"
                    },
                    {
                        type: "Truk",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Motorcycle",
                        count: "10",
                        rfid: "10"
                    },
                ],
                area_polygon: [
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.908617, lng: 104.167717 },
                    { lat: 0.886035, lng: 104.172943 },
                    { lat: 0.888461, lng: 104.185262 },
                    { lat: 0.882302, lng: 104.189928 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.854867, lng: 104.142145 },
                    { lat: 0.859746, lng: 104.121179 },
                ],
                marker_position: { lat: 0.872500, lng: 104.151167 },
                info_position: { lat: 0.887433, lng: 104.151424 },
            },
            {
                label: "Area 4",
                vehicle_area: 15,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "10",
                        rfid: "10"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "2",
                        rfid: "2"
                    },
                    {
                        type: "Government (Red)",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "0",
                        rfid: "0"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Truk",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "10",
                        rfid: "10"
                    },
                ],
                area_polygon: [
                    { lat: 0.885796, lng: 104.201128 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.881778, lng: 104.229958 },
                    { lat: 0.884398, lng: 104.219125 },
                ],
                marker_position: { lat: 0.838944, lng: 104.175586 },
                info_position: { lat: 0.853021, lng: 104.175603 },
            },
            {
                label: "Area 5",
                vehicle_area: 20,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "10",
                        rfid: "10"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Government (Red)",
                        count: "5",
                        rfid: "5"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "1",
                        rfid: "1"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "1",
                        rfid: "1"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Truk",
                        count: "3",
                        rfid: "3"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "10",
                        rfid: "10"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.868605, lng: 104.264192 },
                    { lat: 0.839769, lng: 104.265565 },
                    { lat: 0.828956, lng: 104.250115 },
                    { lat: 0.811792, lng: 104.244965 },
                    { lat: 0.776776, lng: 104.166688 },
                ],
                marker_position: { lat: 0.802250, lng: 104.183452 },
                info_position: { lat: 0.815252, lng: 104.183753 },
            }
        ]
    },
    {
        time_range: "Weekly",
        total_vehicle: 700,
        areas: [
            {
                label: "All Area",
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "350",
                        rfid: "350 "
                    },
                    {
                        type: "Public (Yellow)",
                        count: "105",
                        rfid: "105"
                    },
                    {
                        type: "Government (Red)",
                        count: "140",
                        rfid: "140"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "70",
                        rfid: "70"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "217",
                        rfid: "217"
                    },
                    {
                        type: "Truk",
                        count: "126",
                        rfid: "126"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "182",
                        rfid: "182"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "28",
                        rfid: "28"
                    },
                    {
                        type: "Motorcycle",
                        count: "147",
                        rfid: "147"
                    },
                ]
            },
            {
                label: "Area 1",
                vehicle_area: 70,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "30",
                        rfid: "30"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "14",
                        rfid: "14"
                    },
                    {
                        type: "Government (Red)",
                        count: "14",
                        rfid: "14"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "7",
                        rfid: "7"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "14",
                        rfid: "14"
                    },
                    {
                        type: "Truk",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Motorcycle",
                        count: "7",
                        rfid: "7"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.913545, lng: 104.088710 },
                    { lat: 0.926332, lng: 104.092229 },
                    { lat: 0.935601, lng: 104.084848 },
                    { lat: 0.936871, lng: 104.080300 },
                    { lat: 0.944932, lng: 104.076686 },
                    { lat: 0.951642, lng: 104.080974 },
                    { lat: 0.962441, lng: 104.076896 },
                    { lat: 0.956270, lng: 104.091333 },
                    { lat: 0.960237, lng: 104.096953 },
                    { lat: 0.964093, lng: 104.104998 },
                    { lat: 0.959355, lng: 104.110178 },
                    { lat: 0.964534, lng: 104.120758 },
                    { lat: 0.956270, lng: 104.122852 },
                    { lat: 0.954066, lng: 104.129354 },
                    { lat: 0.941615, lng: 104.128803 },
                ],
                marker_position: { lat: 0.936755, lng: 104.097937 },
                info_position: { lat: 0.951193, lng: 104.097455 },
            },
            {
                label: "Area 2",
                vehicle_area: 140,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "70",
                        rfid: "70"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Government (Red)",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "7",
                        rfid: "7"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "49",
                        rfid: "49"
                    },
                    {
                        type: "Truk",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "70",
                        rfid: "70"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.866869, lng: 104.090014 },
                    { lat: 0.874837, lng: 104.108885 },
                    { lat: 0.866977, lng: 104.115091 },
                    { lat: 0.859746, lng: 104.121179 },
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.929238, lng: 104.157913 },
                    { lat: 0.939646, lng: 104.145293 },
                    { lat: 0.946291, lng: 104.136748 },
                ],
                marker_position: { lat: 0.903664, lng: 104.127249 },
                info_position: { lat: 0.919101, lng: 104.126707 },
            },
            {
                label: "Area 3",
                vehicle_area: 245,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "105",
                        rfid: "105"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Government (Red)",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "49",
                        rfid: "49"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "70",
                        rfid: "70"
                    },
                    {
                        type: "Truk",
                        count: "49",
                        rfid: "49"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Motorcycle",
                        count: "70",
                        rfid: "70"
                    },
                ],
                area_polygon: [
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.908617, lng: 104.167717 },
                    { lat: 0.886035, lng: 104.172943 },
                    { lat: 0.888461, lng: 104.185262 },
                    { lat: 0.882302, lng: 104.189928 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.854867, lng: 104.142145 },
                    { lat: 0.859746, lng: 104.121179 },
                ],
                marker_position: { lat: 0.872500, lng: 104.151167 },
                info_position: { lat: 0.887433, lng: 104.151424 },
            },
            {
                label: "Area 4",
                vehicle_area: 105,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "70",
                        rfid: "70"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "14",
                        rfid: "14"
                    },
                    {
                        type: "Government (Red)",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "0",
                        rfid: "0"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Truk",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "70",
                        rfid: "70"
                    },
                ],
                area_polygon: [
                    { lat: 0.885796, lng: 104.201128 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.881778, lng: 104.229958 },
                    { lat: 0.884398, lng: 104.219125 },
                ],
                marker_position: { lat: 0.838944, lng: 104.175586 },
                info_position: { lat: 0.853021, lng: 104.175603 },
            },
            {
                label: "Area 5",
                vehicle_area: 140,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "70",
                        rfid: "70"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Government (Red)",
                        count: "35",
                        rfid: "35"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "7",
                        rfid: "7"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "7",
                        rfid: "7"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "49",
                        rfid: "49"
                    },
                    {
                        type: "Truk",
                        count: "21",
                        rfid: "21"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "70",
                        rfid: "70"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.868605, lng: 104.264192 },
                    { lat: 0.839769, lng: 104.265565 },
                    { lat: 0.828956, lng: 104.250115 },
                    { lat: 0.811792, lng: 104.244965 },
                    { lat: 0.776776, lng: 104.166688 },
                ],
                marker_position: { lat: 0.802250, lng: 104.183452 },
                info_position: { lat: 0.815252, lng: 104.183753 },
            }
        ]
    },
    {
        time_range: "Monthly",
        total_vehicle: 3000,
        areas: [
            {
                label: "All Area",
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "1.500",
                        rfid: "1.500"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "450",
                        rfid: "450"
                    },
                    {
                        type: "Government (Red)",
                        count: "600",
                        rfid: "600"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "300",
                        rfid: "300"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "930",
                        rfid: "930"
                    },
                    {
                        type: "Truk",
                        count: "540",
                        rfid: "540"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "780",
                        rfid: "780"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "120",
                        rfid: "120"
                    },
                    {
                        type: "Mo630orcycle",
                        count: "630",
                        rfid: "0"
                    },
                ]
            },
            {
                label: "Area 1",
                vehicle_area: 300,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "60",
                        rfid: "60"
                    },
                    {
                        type: "Government (Red)",
                        count: "60",
                        rfid: "60"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "30",
                        rfid: "30"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "60",
                        rfid: "60"
                    },
                    {
                        type: "Truk",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "30",
                        rfid: "30"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "30",
                        rfid: "30"
                    },
                    {
                        type: "Motorcycle",
                        count: "30",
                        rfid: "30"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.913545, lng: 104.088710 },
                    { lat: 0.926332, lng: 104.092229 },
                    { lat: 0.935601, lng: 104.084848 },
                    { lat: 0.936871, lng: 104.080300 },
                    { lat: 0.944932, lng: 104.076686 },
                    { lat: 0.951642, lng: 104.080974 },
                    { lat: 0.962441, lng: 104.076896 },
                    { lat: 0.956270, lng: 104.091333 },
                    { lat: 0.960237, lng: 104.096953 },
                    { lat: 0.964093, lng: 104.104998 },
                    { lat: 0.959355, lng: 104.110178 },
                    { lat: 0.964534, lng: 104.120758 },
                    { lat: 0.956270, lng: 104.122852 },
                    { lat: 0.954066, lng: 104.129354 },
                    { lat: 0.941615, lng: 104.128803 },
                ],
                marker_position: { lat: 0.936755, lng: 104.097937 },
                info_position: { lat: 0.951193, lng: 104.097455 },
            },
            {
                label: "Area 2",
                vehicle_area: 600,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "300",
                        rfid: "300"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Government (Red)",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "30",
                        rfid: "30"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "30",
                        rfid: "30"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "210",
                        rfid: "210"
                    },
                    {
                        type: "Truk",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "300",
                        rfid: "300"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.866869, lng: 104.090014 },
                    { lat: 0.874837, lng: 104.108885 },
                    { lat: 0.866977, lng: 104.115091 },
                    { lat: 0.859746, lng: 104.121179 },
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.929238, lng: 104.157913 },
                    { lat: 0.939646, lng: 104.145293 },
                    { lat: 0.946291, lng: 104.136748 },
                ],
                marker_position: { lat: 0.903664, lng: 104.127249 },
                info_position: { lat: 0.919101, lng: 104.126707 },
            },
            {
                label: "Area 3",
                vehicle_area: 1050,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "450",
                        rfid: "450"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Government (Red)",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "210",
                        rfid: "210"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "300",
                        rfid: "300"
                    },
                    {
                        type: "Truk",
                        count: "210",
                        rfid: "210"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Motorcycle",
                        count: "300",
                        rfid: "300"
                    },
                ],
                area_polygon: [
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.908617, lng: 104.167717 },
                    { lat: 0.886035, lng: 104.172943 },
                    { lat: 0.888461, lng: 104.185262 },
                    { lat: 0.882302, lng: 104.189928 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.854867, lng: 104.142145 },
                    { lat: 0.859746, lng: 104.121179 },
                ],
                marker_position: { lat: 0.872500, lng: 104.151167 },
                info_position: { lat: 0.887433, lng: 104.151424 },
            },
            {
                label: "Area 4",
                vehicle_area: 450,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "300",
                        rfid: "300"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "60",
                        rfid: "60"
                    },
                    {
                        type: "Government (Red)",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "0",
                        rfid: "0"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Truk",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "300",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.885796, lng: 104.201128 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.881778, lng: 104.229958 },
                    { lat: 0.884398, lng: 104.219125 },
                ],
                marker_position: { lat: 0.838944, lng: 104.175586 },
                info_position: { lat: 0.853021, lng: 104.175603 },
            },
            {
                label: "Area 5",
                vehicle_area: 600,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "300",
                        rfid: "300"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Government (Red)",
                        count: "150",
                        rfid: "150"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "30",
                        rfid: "30"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "30",
                        rfid: "30"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "210",
                        rfid: "210"
                    },
                    {
                        type: "Truk",
                        count: "90",
                        rfid: "90"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "300",
                        rfid: "300"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.868605, lng: 104.264192 },
                    { lat: 0.839769, lng: 104.265565 },
                    { lat: 0.828956, lng: 104.250115 },
                    { lat: 0.811792, lng: 104.244965 },
                    { lat: 0.776776, lng: 104.166688 },
                ],
                marker_position: { lat: 0.802250, lng: 104.183452 },
                info_position: { lat: 0.815252, lng: 104.183753 },
            }
        ]
    },
    {
        time_range: "Yearly",
        total_vehicle: 36500,
        areas: [
            {
                label: "All Area",
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "18.250",
                        rfid: "18.250"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "5.475",
                        rfid: "5.475"
                    },
                    {
                        type: "Government (Red)",
                        count: "7.300",
                        rfid: "7.300"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "3.650",
                        rfid: "3.650"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "11.315",
                        rfid: "11.315"
                    },
                    {
                        type: "Truk",
                        count: "6.570",
                        rfid: "6.570"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "9.490",
                        rfid: "9.490"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "1.460",
                        rfid: "1.460"
                    },
                    {
                        type: "Motorcycle",
                        count: "7.665",
                        rfid: "7.665"
                    },
                ]
            },
            {
                label: "Area 1",
                vehicle_area: 3650,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "1.850",
                        rfid: "1.850"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "730",
                        rfid: "730"
                    },
                    {
                        type: "Government (Red)",
                        count: "730",
                        rfid: "730"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "365",
                        rfid: "365"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "730",
                        rfid: "730"
                    },
                    {
                        type: "Truk",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "365",
                        rfid: "365"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "365",
                        rfid: "365"
                    },
                    {
                        type: "Motorcycle",
                        count: "365",
                        rfid: "365"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.913545, lng: 104.088710 },
                    { lat: 0.926332, lng: 104.092229 },
                    { lat: 0.935601, lng: 104.084848 },
                    { lat: 0.936871, lng: 104.080300 },
                    { lat: 0.944932, lng: 104.076686 },
                    { lat: 0.951642, lng: 104.080974 },
                    { lat: 0.962441, lng: 104.076896 },
                    { lat: 0.956270, lng: 104.091333 },
                    { lat: 0.960237, lng: 104.096953 },
                    { lat: 0.964093, lng: 104.104998 },
                    { lat: 0.959355, lng: 104.110178 },
                    { lat: 0.964534, lng: 104.120758 },
                    { lat: 0.956270, lng: 104.122852 },
                    { lat: 0.954066, lng: 104.129354 },
                    { lat: 0.941615, lng: 104.128803 },
                ],
                marker_position: { lat: 0.936755, lng: 104.097937 },
                info_position: { lat: 0.951193, lng: 104.097455 },
            },
            {
                label: "Area 2",
                vehicle_area: 7300,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "3.650",
                        rfid: "3.650"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Government (Red)",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "365",
                        rfid: "365"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "365",
                        rfid: "365"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "2.555",
                        rfid: "2.555"
                    },
                    {
                        type: "Truk",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "3.650",
                        rfid: "3.650"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.936180, lng: 104.125616 },
                    { lat: 0.893907, lng: 104.089040 },
                    { lat: 0.866869, lng: 104.090014 },
                    { lat: 0.874837, lng: 104.108885 },
                    { lat: 0.866977, lng: 104.115091 },
                    { lat: 0.859746, lng: 104.121179 },
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.929238, lng: 104.157913 },
                    { lat: 0.939646, lng: 104.145293 },
                    { lat: 0.946291, lng: 104.136748 },
                ],
                marker_position: { lat: 0.903664, lng: 104.127249 },
                info_position: { lat: 0.919101, lng: 104.126707 },
            },
            {
                label: "Area 3",
                vehicle_area: 12775,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "5.475",
                        rfid: "5.475"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Government (Red)",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "2.555",
                        rfid: "2.555"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "3.650",
                        rfid: "3.650"
                    },
                    {
                        type: "Truk",
                        count: "2.555",
                        rfid: "2.555"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Motorcycle",
                        count: "3.650",
                        rfid: "3.650"
                    },
                ],
                area_polygon: [
                    { lat: 0.904830, lng: 104.158534 },
                    { lat: 0.908617, lng: 104.167717 },
                    { lat: 0.886035, lng: 104.172943 },
                    { lat: 0.888461, lng: 104.185262 },
                    { lat: 0.882302, lng: 104.189928 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.854867, lng: 104.142145 },
                    { lat: 0.859746, lng: 104.121179 },
                ],
                marker_position: { lat: 0.872500, lng: 104.151167 },
                info_position: { lat: 0.887433, lng: 104.151424 },
            },
            {
                label: "Area 4",
                vehicle_area: 5475,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "3.650",
                        rfid: "3.650"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "730",
                        rfid: "730"
                    },
                    {
                        type: "Government (Red)",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "0",
                        rfid: "0"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Truk",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "3.650",
                        rfid: "3.650"
                    },
                ],
                area_polygon: [
                    { lat: 0.885796, lng: 104.201128 },
                    { lat: 0.872224, lng: 104.184889 },
                    { lat: 0.849724, lng: 104.144158 },
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.881778, lng: 104.229958 },
                    { lat: 0.884398, lng: 104.219125 },
                ],
                marker_position: { lat: 0.838944, lng: 104.175586 },
                info_position: { lat: 0.853021, lng: 104.175603 },
            },
            {
                label: "Area 5",
                vehicle_area: 7300,
                license_plate: [
                    {
                        type: "Private (Black)",
                        count: "3.650",
                        rfid: "3.650"
                    },
                    {
                        type: "Public (Yellow)",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Government (Red)",
                        count: "1.825",
                        rfid: "1.825"
                    },
                    {
                        type: "Free Trade (Green)",
                        count: "365",
                        rfid: "365"
                    },
                    {
                        type: "Corps Diplomatic (White)",
                        count: "365",
                        rfid: "365"
                    },
                ],
                vehicle_type: [
                    {
                        type: "Sedan / Pick Up",
                        count: "2.555",
                        rfid: "2.555"
                    },
                    {
                        type: "Truk",
                        count: "1.095",
                        rfid: "1.095"
                    },
                    {
                        type: "Minibus/Hatchback/City Car",
                        count: "3.650",
                        rfid: "3.650"
                    },
                    {
                        type: "Jeep - SUV",
                        count: "0",
                        rfid: "0"
                    },
                    {
                        type: "Motorcycle",
                        count: "0",
                        rfid: "0"
                    },
                ],
                area_polygon: [
                    { lat: 0.807209, lng: 104.154350 },
                    { lat: 0.867404, lng: 104.242906 },
                    { lat: 0.868605, lng: 104.264192 },
                    { lat: 0.839769, lng: 104.265565 },
                    { lat: 0.828956, lng: 104.250115 },
                    { lat: 0.811792, lng: 104.244965 },
                    { lat: 0.776776, lng: 104.166688 },
                ],
                marker_position: { lat: 0.802250, lng: 104.183452 },
                info_position: { lat: 0.815252, lng: 104.183753 },
            }
        ]
    },
]