import { Box, IconButton, Modal } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { useState } from "react";
import PaginationControl from "../pagination/Pagination";
import { dataTrafficEventLog } from "../../../utils/datajson/dataTrafficEventLog";
import CloseIcon from "@mui/icons-material/Close";
import dateHelper from "../../../utils/helper/dateHelper";

const TrafficEventLogsTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState('');

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DetailModal = () => {
    return (
      <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <Box sx={{ ...style, width: "50%", padding: 5 }}>
        <IconButton
          sx={{ position: "absolute", top: 12, right: 12 }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <img src={currentImg} alt={'image'} style={{ height:'70%', width: '70%' }}/>
      </Box>
    </Modal>
    )
  }

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "cameraId",
      headerName: "Camera ID",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "plateNo",
      headerName: "Plate No.",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "plateColor",
      headerName: "Plate Color",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "rfid",
      headerName: "RFID Info",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "carType",
      headerName: "Car Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "carColor",
      headerName: "Car Color",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "date",
      headerName: "Date / Time",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const base64ImageData = `data:image/jpeg;base64,${params.row.image}`;
        return (
          <img
          height={'50px'}
          width={'50px'}
          style={{ cursor: 'pointer'}}
          src= {base64ImageData}
          onClick={()=> {
            setCurrentImg(base64ImageData)
            setOpen(true)
          }}/>
        );
      },
    },
  ];

  // REGION: INIT ROWS
  const rows = data?.data?.data?.map((row: any, index: number) => ({
    id: index,
    cameraId: row.camera_id != "" ? row.camera_id : "-",
    plateNo: row.plate_no != "" ? row.plate_no : "-",
    plateColor: row.plate_color != "" ? row.plate_color : "-",
    carType: row.car_type != "" ? row.car_type : "-",
    carColor: row.car_color != "" ? row.car_color : "-",
    date: row.time_added != "" ? dateHelper.formatDate(row.time_added) : "-",
    image: row.car_image,
    rfid: row.rfid_info != "" ? row.rfid_info : "-",
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows !== undefined ? rows : []}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
      <DetailModal />
    </>
  );
};

export default TrafficEventLogsTable;
