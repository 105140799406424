import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SelectFormm } from "../forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import statistics from "../../../utils/validation/statistics";
import EChart from "../chart/EChart";
import { useGetVehicleCountQuery } from "../../../store/feature/service/userApiSlice";
import { RootState, useAppSelector } from "../../../store";

const VehiclesCount = () => {

  // #region INIT

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const staticsPath = pathname.includes("statistics-count");
  const [sxGrid, setSxGrid] = useState({ xsDaily: 6, xsSelect: 4 });
  const [type, setType] = useState({ log_type: "daily" });
  const [params, setParams] = useState({});
  const [periodType, setPeriodType] = useState("");
  const [period, setPeriod] = useState("");
  const [labelChart, setLabelChart] = useState([]);
  const [valueChart, setValueChart] = useState([]);
  const { data: dataVehicleCount, isLoading } = useGetVehicleCountQuery(params);
  const { isLoadingStatistic } = useAppSelector((state: RootState) => state.user);
  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    timePeriod: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(statistics),
    defaultValues: {
      timePeriod: ""
    },
  });

  const optTimePeriod = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },
  ];

  // #endregion

  // #region USE EFFECT

  useEffect(() => {
    const labels: any = []
    const values: any = []
    if(dataVehicleCount?.data != null) {
      Object.entries(dataVehicleCount?.data)
      .map(([key, value]) => {
        if(periodType == 'today') {
          // labels.push(key.slice(-2) + ":00")
          labels.push("hour " + key.slice(-2))
        } else if(periodType == 'daily') {
          const date = new Date(key);
          const options: any = { day: 'numeric', month: 'short' };
          labels.push(date.toLocaleDateString('en-US', options))
        } else if(periodType == 'monthly') {
          const date = new Date(key);
          const options: any = { month: 'long' };
          labels.push(date.toLocaleDateString('en-US', options))
        } else if(periodType == 'yearly') {
          labels.push(key)
        }
        values.push(value)
      })
    }
    setLabelChart(labels)
    setValueChart(values)
  }, [dataVehicleCount]);

  useEffect(() => {
    reset({ timePeriod: "today" });
    setTodayPeriod()
  }, []);

  // #endregion

  // #region HANDLE TIME PERIOD

  const handleTimePeriod = (e: any) => {
    switch(e.value) {
      case "today": setTodayPeriod(); break;
      case "daily": setDailyPeriod(); break;
      case "monthly": setMonthlyPeriod(); break;
      case "yearly": setYearlyPeriod(); break;
    }
  };

  const setTodayPeriod = () => {

    // SET TEXT TIME PERIOD
    const today = new Date()
    setPeriod(formatDate(today, 'today'))
    setPeriodType('today')

    // SET PARAMS
    let form = {
      dayHourly: getFormatDate(today)
    };
    setParams(form);

  };

  const setDailyPeriod = () => {

    // SET TEXT TIME PERIOD
    const today = new Date();
    const aWeekAgo = new Date().setDate(today.getDate() - 7);
    const formattedToday = formatDate(today, 'daily');
    const formattedAWeek = formatDate(aWeekAgo, 'daily');
    const formattedYear = formatDate(new Date(), 'yearly');
    setPeriod(`${formattedAWeek} - ${formattedToday}, ${formattedYear}`)
    setPeriodType('daily')

    // SET PARAMS
    let form = {
      time_period: "daily",
      dateFrom: getFormatDate(aWeekAgo),
      dateTo: getFormatDate(today),
    };
    setParams(form);

  };

  const setMonthlyPeriod = () => {

    // SET TEXT TIME PERIOD
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);
    const formattedYear = formatDate(new Date(), 'yearly');
    setPeriod(`Januari ${formattedYear} - Desember ${formattedYear}`)
    setPeriodType('monthly')

    // SET PARAMS
    let form = {
      time_period: "monthly",
      dateFrom: getFormatDate(firstDay),
      dateTo: getFormatDate(lastDay),
    };
    setParams(form);

  };

  const setYearlyPeriod = () => {

    // SET TEXT TIME PERIOD
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);
    const formattedYear = formatDate(new Date(), 'yearly');
    setPeriod(formattedYear)
    setPeriodType('yearly')

    // SET PARAMS
    let form = {
      time_period: "yearly",
      dateFrom: getFormatDate(firstDay),
      dateTo: getFormatDate(lastDay),
    };
    setParams(form);

  };

  // #endregion

  // #region DATE FORMATTING

  const formatDate = (dateString: any, periodType: string) => {
    var options: any = {};
    switch(periodType) {
      case "today": {
        options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }
        break
      }
      case "daily": {
        options = {
          day: 'numeric',
          month: 'long',
        }
        break
      }
      case "monthly": {
        options = {
          month: 'long',
          year: 'numeric',
        }
        break
      } 
      case "yearly": {
        options = {
          year: 'numeric',
        }
        break
      }
    }
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  };

  const getFormatDate = (dateString: any) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return formattedDate;
  };



  // #endregion

  // #region RETURN

  return (
    <Box component="form" paddingY="20px">
      <Grid container spacing={3}>
        <Grid item xs={sxGrid.xsDaily}>
          <Typography variant="h3" sx={{ mb: '8px' }}>
            Vehicles Count
          </Typography>
          <Typography variant="h4" sx={{ mb: '12px' }}>
            In and Out
          </Typography>
          <Typography variant="body1">
            {period}
          </Typography>
        </Grid>
        <Grid item xs={sxGrid.xsSelect}>
          <SelectFormm
            name="timePeriod"
            defaultValue={"today"}
            options={optTimePeriod}
            placeholder="Please Select"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
            sxFL={{ backgroundColor: "red" }}
            onChangeForm={handleTimePeriod}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            sx={{ mt: 1.1 }}
            onClick={() => navigate("/statistics-count")}>
            Detail
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            { !isLoadingStatistic ? <EChart
              labelLegend= {type}
              labelProp={labelChart}
              successProp={valueChart}
            /> : <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'100%'} height={staticsPath ? '650px' : '345px'}/>
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  // #endregion

};

export default VehiclesCount;
