import * as yup from "yup";
import { validationNotEmpty, validationEmailField, validationConfirmNewPassword, validationConfirmNewPasswordL } from "./defaultValidations";

export default yup.object({
  firstName: validationNotEmpty("Firstname"),
  lastName: validationNotEmpty("Lastname"),
  email: validationEmailField("Email"),
  phoneNumber: validationNotEmpty("Phone Number"),
  role: validationNotEmpty("Role"),
  status: validationNotEmpty("Status")
});