import { Box } from "@mui/system";
import {Paper} from "@mui/material";
import VehiclesCount from "../../components/molecules/dashboard/VehiclesCount";
import HeaderDashboard from "./HeaderDashboard";
import GridTable from '../../components/molecules/dashboard/GridTable'
import { dataLicencePlate } from '../../utils/datajson/dataLicensePlate'
import "chart.js/auto";

const Dashboard = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <HeaderDashboard />
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          "& > :not(style)": {
            m: 1,
          },
          mt: 2,
        }}
      >
        <Paper elevation={2} sx={{ p: 4, flex: 1, border: "1px solid #ccc" }}>
          <VehiclesCount />
        </Paper>
        <GridTable title={'Plate'} dataGrid={dataLicencePlate}/>
        {/* <Paper elevation={2} sx={{ p: 4, flex: 1, border: "1px solid #ccc" }}>
          <VehiclesType />
        </Paper> */}
      </Box>
      {/* <DashboardGrid /> */}
    </Box>
  );
};

export default Dashboard;
