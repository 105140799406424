export const dataLicencePlate =[
{
    type: "Private (Black)",
    count: "40.000",
},{
    type: "Public (Yellow)",
    count: "20.000",
},{
    type: "Government (Red)",
    count: "5.000",
},{
    type: "Free Trade (Green)",
    count: "0",
},{
    type: "Corps Diplomatic (White)",
    count: "200",
},
]