import IRoutes from "../interfaces/IRoutes";
import AreaTracking from "../pages/areaTracking/AreaTracking";

const AreaTrackingRoute: Array<IRoutes> = [
  {
    element: <AreaTracking />,
    path: "/area-tracking",
  }
];

export { AreaTrackingRoute };
