
import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';
import { TrafficEventsRoute } from './trafficEventLogs';
import { VehicleTrackingRoute } from './vehicleTracking';
import VehiclesCount from '../components/molecules/dashboard/VehiclesCount';
import VehiclesType from '../components/molecules/dashboard/VehiclesType';
import ChangePassword from '../pages/profile/ChangePassword';
import { AreaTrackingRoute } from './areaTracking';

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <VehiclesCount />,
        path: '/statistics-count'
      },
      {
        element: <VehiclesType />,
        path: '/statistics-type'
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Profile />,
        path: '/profile',
      },
      {
        element: <ChangePassword />,
        path: '/change-password',
      },
      ...TrafficEventsRoute,
      ...VehicleTrackingRoute,
      ...AreaTrackingRoute,
    ],
  },
];

export default routes;
