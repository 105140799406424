import { createSlice } from "@reduxjs/toolkit";
import { vehicleTrackingApiSlice } from "./service/vehicleTrackingApiSlice";

interface initialStateI {
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  isLoadingEvent: false
};

const vehicleTrackingSlice = createSlice({
    name: 'vehicleTracking',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        vehicleTrackingApiSlice.endpoints.getResultBlob.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        vehicleTrackingApiSlice.endpoints.getResultBlob.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
        }
      )
      .addMatcher(
        vehicleTrackingApiSlice.endpoints.getResultBlob.matchRejected,
        state => {
          state.isLoadingEvent = false;
        }
      )
    }
})

export const vehicleTrackingReducer = vehicleTrackingSlice.reducer