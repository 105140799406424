// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const areaTrackingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAreaTrackingList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/devices`,
          params: params,
        };
      },
      providesTags: ["GetAllAreaTrackingList"],
    }),
    createAreaTracking: builder.mutation<any, any>({
      query: (body) => ({
        url: "/device/register",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreateAreaTrackingList"],
    }),
    approvalDevice: builder.mutation<any, any>({
      query: (params) => {
        const { uid, status } = params
        return {
          url: `/device/approval/${uid}`,
          method: "PUT",
          body: {
            device_status: status
          }
        }
      },
      invalidatesTags: ["ApprovalDevice", "GetAllAreaTrackingList"],
    }),
  }),
});

export const { useGetAllAreaTrackingListQuery, useCreateAreaTrackingMutation, useApprovalDeviceMutation } =
  areaTrackingApiSlice;
