import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import PaginationControl from '../pagination/Pagination';
import Swal from 'sweetalert2';
import { useApprovalDeviceMutation } from '../../../store/feature/service/areaTrackingApiSlice';

const AreaTrackingTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [approvalDevice, { isSuccess }] = useApprovalDeviceMutation();
  
  const { role_id } = useAppSelector(
    (state: RootState) => state.user
  );

   if (isSuccess) {
    // Swal.fire({
    //   icon: "success",
    //   title: "user registered",
    //   showCancelButton: false,
    //   confirmButtonText: "OK",
    //   confirmButtonColor: "#051438",
    //   text: "Your configuration has been updated",
    // }).then(() => {
    // });
  }

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: DISPATCH DELETE DATA
    const switchStatus = (status: any) => {
      Swal.fire({
        title: `${status} Confirmation`,
        text: `Are you sure you want to ${status} this device?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#808080',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            uid: params.row.id,
            status: status == 'Activate' ? 'ACTIVE' : 'INACTIVE'
          }
          approvalDevice(data)
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button
          disabled={params.row.status == 'ACTIVE'}
          variant="contained"
          onClick={() => switchStatus('Activate')}>
            Active
          </Button>
          <Button
          disabled={params.row.status == 'INACTIVE'}
          variant="contained"
          color="error"
          onClick={() => switchStatus('Deactivate')}>
            Inactive
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'vehicleType',
      headerName: 'Vehicle Type',
      flex: 1,
      minWidth: 250,
      headerClassName: 'centered',
    },
    {
      field: 'vehicleCount',
      headerName: 'Vehicle Count',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'rfidCount',
      headerName: 'RFID Count',
      flex: 1,
      minWidth: 150,
    },
  ];

  // REGION: INIT ROWS
  // const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [] 

  const rows = data?.map((row: any, index: number) => ({ 
    id: index,
    vehicleType: row.type,
    vehicleCount: row.count,
    rfidCount: row.rfid,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '350px'}}>
        <DataGrid
          rows={rows !== undefined ? rows : [] }
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      {/* <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      /> */}
    </>
  );
};

export default AreaTrackingTable;
