import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { clearStorage, getToken } from '../../../utils/storage';
import { removeToken } from '../userReducer';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API }/`,
    // prepareHeaders: (headers, { endpoint, getState }) => {
    //   const token = getToken();
    //   if (token) {
    //     headers.set('Authorization', `Bearer ${token}`);
    //   }
    //   if (endpoint == "generateLicense") {
    //     const state: any = getState()
    //     const licenseString = state.license.licenseString
    //     headers.set("encrypted-string", licenseString)
    //   }
    //   return headers;
    // },
  });


  const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);
    let resp: any = result?.error?.data
    if(resp?.status_code === 401){
      clearStorage();
      window.location.href= '/login';
    }
    return result;
  };

  export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    tagTypes: [
      'GetAllAreaTrackingList',
      'GetAdminManagementList',
      'GetLicenseServerList',
      'GenerateLicense',
      'GetTrafficEventLogsList',
      'GetVehicleTrackingList',
      'submitLogin',
      'CreateAreaTrackingList',
      'ApprovalDevice',
      'CreateAdminManagement',
      'UpdateAdminManagement',
      'GetDashboardHeader',
      'GetVehicleTrackingBlob',
      'GetDashboardBar',
      'GetDashboardBarFilter',
      'GetRoleList',
      'GetAdminUserDetail',
      'DeleteAdminManagement',
      'UpdatePassword',
      'GetDashboardGrid',
      'GetProfileDetail',
      'UpdateProfilePassword',
      'GetDetectedVehicle',
      'GetLicensePlateType',
      'GetVehicleCount'
    ],
  });
  
  export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
  