export const dataVehicleTypeStatistic = [
    {
        "hour": "09:00:00",
        "success_amount": 10,
        "failed_amount": 11
    },
    {
        "hour": "10:00:00",
        "success_amount": 26,
        "failed_amount": 30
    }
]



export const dataVehicleTypeDate = {
    "2023-06-23": {
        "Success": 2,
        "Failed": 0
    },
    "2023-06-26": {
        "Success": 4,
        "Failed": 0
    },
    "2023-06-27": {
        "Success": 1,
        "Failed": 1
    },
    "2023-07-05": {
        "Success": 3,
        "Failed": 1
    },
    "2023-07-10": {
        "Success": 1,
        "Failed": 1
    },
    "2023-07-11": {
        "Success": 9,
        "Failed": 5
    },
    "2023-07-12": {
        "Success": 35,
        "Failed": 7
    },
    "2023-07-13": {
        "Success": 3,
        "Failed": 5
    },
    "2023-07-14": {
        "Success": 9,
        "Failed": 21
    },
    "2023-07-17": {
        "Success": 18,
        "Failed": 0
    },
    "2023-07-18": {
        "Success": 18,
        "Failed": 0
    },
    "2023-07-20": {
        "Success": 16,
        "Failed": 5
    },
    "2023-07-21": {
        "Success": 4,
        "Failed": 3
    },
    "2023-07-25": {
        "Success": 27,
        "Failed": 10
    },
    "2023-07-26": {
        "Success": 54,
        "Failed": 23
    },
    "2023-07-31": {
        "Success": 11,
        "Failed": 18
    },
    "2023-08-01": {
        "Success": 23,
        "Failed": 0
    },
    "2023-08-04": {
        "Success": 8,
        "Failed": 37
    },
    "2023-08-09": {
        "Success": 43,
        "Failed": 0
    },
    "2023-08-10": {
        "Success": 32,
        "Failed": 0
    },
    "2023-08-29": {
        "Success": 2,
        "Failed": 0
    },
    "2023-09-04": {
        "Success": 2,
        "Failed": 0
    },
    "2023-09-05": {
        "Success": 1,
        "Failed": 2
    },
    "2023-09-07": {
        "Success": 1,
        "Failed": 1
    },
    "2023-09-08": {
        "Success": 2,
        "Failed": 0
    },
    "2023-09-12": {
        "Success": 30,
        "Failed": 0
    },
    "2023-09-13": {
        "Success": 6,
        "Failed": 4
    },
    "2023-09-14": {
        "Success": 5,
        "Failed": 33
    },
    "2023-09-15": {
        "Success": 8,
        "Failed": 5
    },
    "2023-09-18": {
        "Success": 11,
        "Failed": 53
    }
}