import * as yup from "yup";

import {
  validationStringSelect,
  validationTextField,
} from "./defaultValidations";

export default yup.object({
  eventLog: validationStringSelect("Event Log"),
  time: validationStringSelect("Event Log"),
  dateFrom: validationTextField("Date From"),
});
