import * as yup from "yup";

import {
  validationStringSelect,
  validationTextField,
} from "./defaultValidations";

export default yup.object({
  timePeriod: validationStringSelect("Time Period")
});
