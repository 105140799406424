import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import SelectForm from "../forms/selectFormm";

const schema = yup.object({
  title: yup.string().required(),
});

const AreaTrackingSearch = ({ params, setParams, firstOpen }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [timeRange, setTimeRange] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    timeRange: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      timeRange: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optTimeRange = [
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
    {
      label: "Custom",
      value: "Custom",
    },
  ];

  const handleTimeRangeSelect = (e: any) => { setTimeRange(e.value); }; 
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(dateEnd))) {
        setAllDate(NewDate);
      } else {
        setDateStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      dateStart: date,
      dateEnd: date,
    });
    setDateStart(date);
    setDateEnd(date);
  }
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setDateEnd(NewDate);
  };
  const conditionCheck = () => {
    if(dateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Date Start"
      });
      reset({
        dateStart: "",
        dateEnd: "",
      });
      setDateEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(timeRange), [timeRange], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      timeRange: timeRange,
      dateStart: dateStart,
      dateEnd: dateEnd,
    });
  }, [searchFilterDebounced]);

  useEffect(() => {
    if(firstOpen) {
      reset({
        timeRange: "Daily"
      });
    }
  })
  
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
      <SelectForm 
          name="timeRange"
          defaultValue={0}
          options={optTimeRange}
          placeholder="Time Range"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleTimeRangeSelect}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(dateStart).getTime())}
          onChange={handleDateEnd}
        />
      </Box>
    </Box>
  );
};

export default AreaTrackingSearch;
