import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import TrafficEventLogsSearch from '../../components/molecules/trafficEventLogs/TrafficEventLogsSearch';
import TrafficEventLogsTable from '../../components/molecules/trafficEventLogs/TrafficEventLogsTable';
import { useGetTrafficEventLogsListQuery } from '../../store/feature/service/trafficEventLogsApiSlice';

const TrafficEventLogs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetTrafficEventLogsListQuery(params);
  const { isLoadingData } = useAppSelector((state: RootState) => state.trafficEventLogs);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading || isLoadingData}
      isLoadingEvent={false}
      titleWidth={'300px'}>
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}>
          Traffic Event Log
        </Typography>
        <TrafficEventLogsSearch params={params} setParams={setParams} />
        <TrafficEventLogsTable
          data={data}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default TrafficEventLogs;
