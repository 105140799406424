import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const TrafficEventLogsSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [cameraId, setCameraId] = useState("");
  const [plateNo, setPlateNoName] = useState("");
  const [plateColor, setPlateColor] = useState("");
  const [carType, setCarType] = useState("");
  const [carColor, setCarColor] = useState("");
  const [rfid, setRfid] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    cameraId: string;
    plateNo: string;
    plateColor: string;
    carType: string;
    carColor: string;
    rfid: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      cameraId: "",
      plateNo: "",
      plateColor: "",
      carType: "",
      carColor: "",
      rfid: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optTypeSelect = [
    {
      label: "SUV_S",
      value: "SUV_S",
    },
    {
      label: "SUV_PIKA",
      value: "SUV_PIKA",
    },
    {
      label: "SUV_L",
      value: "SUV_L",
    },
    {
      label: "SUV_QINGKE",
      value: "SUV_QINGKE",
    },
    {
      label: "SUV_Z",
      value: "SUV_Z",
    },
    {
      label: "SUV_B",
      value: "SUV_B",
    },
    {
      label: "SUV_M",
      value: "SUV_M",
    },
    {
      label: "MPV",
      value: "MPV",
    },
    {
      label: "CAR_THR",
      value: "CAR_THR",
    },
    {
      label: "CAR_TWO",
      value: "CAR_TWO",
    },
    {
      label: "TRUCK_KEI",
      value: "TRUCK_KEI",
    },
    {
      label: "WEIMIAN",
      value: "WEIMIAN",
    },
    {
      label: "NOT",
      value: "NOT",
    },
    {
      label: "PAS",
      value: "PAS",
    },
  ];

  const optColorSelect = [
    {
      label: "Black",
      value: "Black",
    },
    {
      label: "White",
      value: "White",
    },
    {
      label: "Gray",
      value: "Gray",
    },
    {
      label: "Silver",
      value: "Silver",
    },
    {
      label: "Cyan",
      value: "Cyan",
    },
    {
      label: "Brown",
      value: "Brown",
    },
    {
      label: "Blue",
      value: "Blue",
    },
    {
      label: "Red",
      value: "Red",
    },
    {
      label: "Green",
      value: "Green",
    },
  ];

  const handleCameraId = (e: string) => { setCameraId(e); };
  const handlePlateNoSelect = (e: any) => { setPlateNoName(e); };
  const handlePlateColorSelect = (e: any) => { setPlateColor(e); }; 
  const handleCarTypeSelect = (e: any) => { setCarType(e.value); }; 
  const handleCarColorSelect = (e: any) => { setCarColor(e.value); }; 
  const handleRfidSelect = (e: string) => { setRfid(e); };
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(dateEnd))) {
        setAllDate(NewDate);
      } else {
        setDateStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      cameraId: cameraId,
      plateNo: plateNo,
      plateColor: plateColor,
      carType: carType,
      carColor: carColor,
      rfid: rfid,
      dateStart: date,
      dateEnd: date
    });
    setDateStart(date);
    setDateEnd(date);
  }
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setDateEnd(NewDate);
  };
  const conditionCheck = () => {
    if(dateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Date"
      });
      reset({
        cameraId: cameraId,
        plateNo: plateNo,
        plateColor: plateColor,
        carType: carType,
        carColor: carColor,
        // rfid: rfid,
        dateStart: "",
        dateEnd: "",
      });
      setDateEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(cameraId), [cameraId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(plateNo), [plateNo], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(plateColor), [plateColor], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(carType), [carType], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(carColor), [carColor], 500);  
  useDebouncedEffect(() => setSearchFilterDebounced(rfid), [rfid], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      camera_id: cameraId,
      plate_no: plateNo,
      plate_color: plateColor,
      car_type: carType,
      car_color: carColor,
      eventRfid: rfid,
      start_time_added: dateStart,
      end_time_added: dateEnd,
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="cameraId"
          errors={errors}
          control={control}
          required
          placeholder="Camera ID"
          maxLength={100}
          isLoading={false}
          onChange={handleCameraId}
        />
        <InputForm
          name="plateNo"
          errors={errors}
          control={control}
          required
          placeholder="Plate No"
          maxLength={100}
          isLoading={false}
          onChange={handlePlateNoSelect}
        />
        <InputForm
          name="plateColor"
          errors={errors}
          control={control}
          required
          placeholder="Plate Color"
          maxLength={100}
          isLoading={false}
          onChange={handlePlateColorSelect}
        />
        <InputForm
          name="rfid"
          errors={errors}
          control={control}
          required
          placeholder="RFID Info"
          maxLength={100}
          isLoading={false}
          onChange={handleRfidSelect}
        />
        <SelectForm 
          name="carType"
          defaultValue={1}
          options={optTypeSelect}
          placeholder="Car Type"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleCarTypeSelect}
        />
        <SelectForm 
          name="carColor"
          defaultValue={1}
          options={optColorSelect}
          placeholder="Car Color"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleCarColorSelect}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(dateStart).getTime())}
          onChange={handleDateEnd}
        />
      </Box>
    </Box>
  );
};

export default TrafficEventLogsSearch;
