import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { SelectFormm } from "../forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BarChart from "../chart/BarChart";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePickerForm from "../forms/datePickerForm";
import {
  useGetDashboardBarFilterQuery,
  useGetDashboardBarQuery,
} from "../../../store/feature/service/dashboardApiSlice";
import dayjs from "dayjs";
import statistics from "../../../utils/validation/statistics";
import hourlyStatistics from "../../../utils/validation/hourlyStatistics";
import ChartExample from "../chart/ChartExample";
import EChart from "../chart/EChart";
import { dataVehicleTypeStatistic, dataVehicleTypeDate } from "../../../utils/datajson/dataVehicleTypeStatistic";

const VehiclesType = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const staticsPath = pathname.includes("statistics-type");
  const [sxGrid, setSxGrid] = useState({ xsDaily: 6, xsSelect: 4 }); // default value for
  const [type, setType] = useState({ log_type: "result_log" });
  const [params, setParams] = useState({});
  const [dateHourly, setDateHourly] = useState(false);
  const [dateHourSubmit, setDateHourSubmit] = useState(false);

  const { data } = useGetDashboardBarQuery(type);
  const { data: dataFilter } = useGetDashboardBarFilterQuery(params);

  // const dataBar = data?.data;
  const dataBar = dataVehicleTypeStatistic;
  // const dataFiltered = dataFilter?.data !== undefined || null ? dataFilter?.data : {};
  const dataFiltered = dataVehicleTypeDate;
  const label = dataBar?.map((item: any) =>
    dayjs("1970-01-01T" + item.hour).format("hh:mm A")
  );
  const success = dataBar?.map((item: any) => item.success_amount);
  const failed = dataBar?.map((item: any) => item.failed_amount);

  // data for chart on statistics page

  const labelFilter = Object.keys(dataFiltered);
  const labelFilterHours = labelFilter?.map(
    (
      item: any // for filtered data by hourly on statisics page;
    ) => dayjs(item).format("hh:mm A")
  );
  const labelFilteredWithHour = dateHourSubmit ? labelFilterHours : labelFilter;
  const successFilter = Object.values(dataFiltered).map(
    (item: any) => item.Success
  );
  const failedFilter = Object.values(dataFiltered).map(
    (item: any) => item.Failed
  );

  const currentDate = new Date();
  const numberDate = dateHourly ? 30 : 365
  const minDate = currentDate.setDate(currentDate.getDate() - numberDate);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    eventLog: string;
    time: string;
    dateFrom: string;
    dateTo: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(dateHourly ? hourlyStatistics : statistics),
    defaultValues: {
      eventLog: "",
      time: "",
      dateFrom: "",
      dateTo: "",
    },
  });

  useEffect(() => {
    if (staticsPath) {
      setSxGrid({
        xsDaily: 12, // default value for page title
        xsSelect: 2.5, // default value for componen on statistics page
      });
    }
    reset({ eventLog: "result_log" });
  }, []);

  const optLogType = [
    {
      label: "Daily",
      value: "result_log",
    },
    {
      label: "Weekly",
      value: "event_log",
    },
    {
      label: "Monthly",
      value: "event_log_2",
    },
  ];

  const optTime = [
    {
      label: "Hourly",
      value: "hourly",
    },
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },
  ];

  const handleLogType = (e: any) => {
    // if (!staticsPath) {
      if (e !== null) {
        setType({ log_type: e.value });
        console.log("e.value ==> ", e.value);
      } else {
        setType({ log_type: "" });
      }
    // }
  };

  const handleTimeSelect = (e: any) => {
    if (e !== null) {
      if (e.value === "hourly") {
        return setDateHourly(true);
      }
    }
    setDateHourly(false);
  };

  const onSubmit = (e: any) => {
    console.log("submit ====> ", e);
    if (e.time === "hourly") {
      setDateHourSubmit(true);
    } else {
      setDateHourSubmit(false);
    }

    let form = {
      log_type: e.eventLog,
      time_period: e.time,
      dateFrom: dayjs(e.dateFrom).format("YYYY-MM-DD"),
      dateTo: dayjs(e.dateTo).format("YYYY-MM-DD"),
    };
    let formHourly = {
      log_type: e.eventLog,
      time_period: e.time,
      dayHourly: dayjs(e.dateFrom).format("YYYY-MM-DD"),
    };
    setParams(dateHourly ? formHourly : form);
    // setType({ log_type: e.value });
  };

  // const labelProp = staticsPath ? labelFilter : label;
  // const successProp = 'Success Value';
  // const failedProp = 'Failed Value';

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Grid container spacing={3}>
        <Grid item xs={sxGrid.xsDaily}>
          <Typography variant="h3" sx={{ mb: '8px' }}>
            Vehicles Type
          </Typography>
          <Typography variant="h4">
            In and Out
          </Typography>
        </Grid>
        <Grid item xs={sxGrid.xsSelect}>
          <SelectFormm
            name="eventLog"
            defaultValue={"result_log"}
            options={optLogType}
            placeholder="Please Select"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
            sxFL={{ backgroundColor: "red" }}
            onChangeForm={handleLogType}
            // isClear
          />
        </Grid>
        {staticsPath && (
          <>
            <Grid item xs={2.5}>
              <SelectFormm
                name="time"
                defaultValue={1}
                options={optTime}
                placeholder="Please Select"
                disabled={false}
                isLoading={false}
                control={control}
                errors={errors}
                required
                sxFL={{ backgroundColor: "red" }}
                onChangeForm={handleTimeSelect}
                isClear
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerForm
                name="dateFrom"
                defaultValue={null}
                control={control}
                errors={errors}
                placeholder="Start Date"
                disabled={false}
                isLoading={false}
                required
                minDate={minDate}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerForm
                name="dateTo"
                defaultValue={null}
                control={control}
                errors={errors}
                placeholder="Start Date"
                disabled={dateHourly}
                isLoading={false}
                minDate={minDate}
                required
              />
            </Grid>
          </>
        )}
        {!staticsPath ? (
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={{ mt: 1.1 }}
              onClick={() => navigate("/statistics-type")}
            >
              Detail
            </Button>
          </Grid>
        ) : (
          <Grid item xs={1}>
            <Button variant="contained" sx={{ mt: 1.1 }} type="submit">
              Submit
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box>
            <EChart
              labelLegend= {type}
              labelProp={staticsPath ? labelFilteredWithHour : label}
              successProp={staticsPath ? successFilter : success}
              failedProp={staticsPath ? failedFilter : failed}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehiclesType;
