import {  Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import VehicleTrackingSearch from '../../components/molecules/vehicleTracking/VehicleTrackingSearch';
import VehicleTrackingTable from '../../components/molecules/vehicleTracking/VehicleTrackingTable';
import { useGetVehicleTrackingListQuery } from '../../store/feature/service/vehicleTrackingApiSlice';
import MapVehicleTracking from '../../components/molecules/maps/MapVehicleTracking';
import { dataVehicleTracking } from '../../utils/datajson/dataVehicleTracking';
import CloseIcon from "@mui/icons-material/Close";

const VehicleTracking = () => {
  const [params, setParams] = useState<any>({});
  const [data, setData] = useState<any>(undefined);
  const [defaultValue, setDefaultValue] = useState({});
  const [open, setOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState('');
  const [firstOpen, setFirstOpen] = useState(true);
  
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DetailModal = () => {
    return (
      <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <Box sx={{ ...style, width: "50%", padding: 5 }}>
        <IconButton
          sx={{ position: "absolute", top: 12, right: 12 }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <img src={currentImg} alt={'image'} style={{ height:'50%', width: '50%' }}/>
      </Box>
    </Modal>
    )
  }

  useEffect(() => {
    if(firstOpen) {
      setFirstOpen(false)
      setParams({
        ...params,
        plateNumber: "B 5555 ABC",
      });
    }
  })

  useEffect(() => {
    let data = dataVehicleTracking.find((item: any) => item.plateNumber == params.plateNumber)
    setData(data)
  }, [params])
  
  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={false}
      isLoadingEvent={false}
      titleWidth={'300px'}>
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}>
          Vehicle Tracking
        </Typography>
        <VehicleTrackingSearch
          params={params}
          setParams={setParams}
          firstOpen={firstOpen}
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <MapVehicleTracking data={data} />
          </Grid>
          <Grid item xs={6}>
            <VehicleTrackingTable
              data={data}
              params={params}
              setParams={setParams}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              setOpen={setOpen}
              setCurrentImg={setCurrentImg}
            />
          </Grid>
        </Grid>
      </Box>
      <DetailModal />
    </ListDataSkeleton>
  );
};

export default VehicleTracking;
