import { Button, Grid, Paper, Typography } from '@mui/material'
import DashTable from '../../organism/dashTable/DashTable'
import { useNavigate } from 'react-router-dom'

const GridTable = ({title, dataGrid}: any) => {
    const navigate = useNavigate()
    // console.log(dataGrid)
  return (
    <Paper elevation={24} sx={{ p: 4, flex: 1, border: "1px solid #ccc" }}>
    <Grid container spacing={2} marginBottom={2}>
      <Grid item xs={12}>
        <Typography variant="h3">
          {title === 'Plate' ? 'License Plate Type': 'Vehicle Type'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {title === 'Plate' ? 'Total by Plate Type' : 'Total by Vehicle Type' }
          </Grid>
          <Grid item xs={4} sx={{display: 'flex', flexDirection: 'row-reverse'}}>
            <Button variant="contained" onClick={()=> navigate('traffic-event-log') }>Detail</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <DashTable dataGrid={dataGrid} title={title}/>
  </Paper>
  )
}

export default GridTable