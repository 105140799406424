import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import PieChart from "../../components/molecules/chart/PieChart";
import { useGetdashboardHeaderQuery } from "../../store/feature/service/dashboardApiSlice";
import dayjs from "dayjs";
import EChartDoughnut from "../../components/molecules/chart/EChartDoughnut";
import formatNumberWithCommas from "../../utils/hash/dotSparator";
import { useGetDetectedVehicleMutation } from "../../store/feature/service/userApiSlice";
import { useEffect } from "react";

const HeaderDashboard = () => {
  const { data } = useGetdashboardHeaderQuery({});
  const totalRequest = data?.data?.request_details;
  const deviceDetails = data?.data?.device_details;
  const currentDate = dayjs();
  const formattedDate = currentDate.format("YYYY MMM DD");

  // console.log("device details ==> ", deviceDetails);
  // console.log("total Request ===> ", totalRequest);
  const totalDetailsRequest = {
    fail_amount: deviceDetails?.inactive_amount,
    fail_percentage: deviceDetails?.inactive_percentage,
    success_amount: deviceDetails?.active_amount,
    success_percentage: deviceDetails?.active_percentage,
    total_request: deviceDetails?.total_devices,
    name: "Device",
  };

  const newTotalRequest = { ...totalRequest, name: "Request" };

  const [getDetectedVehicle, { data: dataCarCount, isLoading }] = useGetDetectedVehicleMutation();

  useEffect(()=> {
    getDetectedVehicle({})
  }, [])

  return (
    <Grid container spacing={4} justifyContent={"center"}>
      <Grid item xs={4}>
        <Paper
          elevation={2}
          sx={{
            p: 4,
            border: "1px solid #ccc",
            width: "100%",
            height: 240,
            flexWrap: "wrap",
          }}
        >
          <Stack spacing={4} justifyContent={"center"}>
            <Typography variant="h4">Welcome Back,</Typography>
            {/* <Typography variant="h3">{data?.data?.username}</Typography> */}
            <Typography variant="h3">John Doe</Typography>
            <Typography variant="h4">Today Date: {formattedDate}</Typography>
          </Stack>
        </Paper>
      </Grid>

    <Grid item xs={4}>
      <Paper
        elevation={2}
        sx={{ p: 4, border: "1px solid #ccc", width: "100%", height: 240 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Detected RFID</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h1">
              {/* {formatNumberWithCommas(deviceDetails?.total_devices || 0)} */}
              0
            </Typography>
          </Grid>
          {/* <Grid item xs={6}> */}
            {/* <PieChart chartData={totalDetailsRequest} /> */}
            {/* <EChartDoughnut chartData={totalDetailsRequest} /> */}
          {/* </Grid> */}
        </Grid>
      </Paper>
    </Grid>

      <Grid item xs={4}>
        <Paper
          elevation={2}
          sx={{ p: 4, border: "1px solid #ccc", width: "100%", height: 240 }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4">Detected Vehicle</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h1">
                {/* {formatNumberWithCommas(deviceDetails?.total_devices || 0)} */}
                {dataCarCount?.data?.car_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </Typography>
            </Grid>
            {/* <Grid item xs={6}> */}
              {/* <PieChart chartData={totalDetailsRequest} /> */}
              {/* <EChartDoughnut chartData={totalDetailsRequest} /> */}
            {/* </Grid> */}
          </Grid>
        </Paper>
      </Grid>
      
    </Grid>
  );
};

export default HeaderDashboard;



//  <Grid item xs={4}>
 //  <Paper
 //    elevation={2}
 //    sx={{ p: 4, border: "1px solid #ccc", width: "100%", height: 270 }}
 //  >
 //    <Grid container spacing={4} sx={{ paddingTop: "0px" }}>
 //      <Grid item xs={12}>
 //        <Typography variant="h4">Detected RFID</Typography>
 //      </Grid>
 //      <Grid item xs={6}>
 //        <Typography variant="h1">
 //          {/* {formatNumberWithCommas(totalRequest?.total_request || 0)} */}
//           50.000
//         </Typography>
//       </Grid>
//       {/* <Grid item xs={6}> */}
//         {/* <div style={{ }}> */}
//         {/* <PieChart chartData={totalRequest} /> */}
//         {/* <Box sx={{ paddingTop: "0 !important" }}>
//           <EChartDoughnut chartData={newTotalRequest} />
//         </Box> */}
//         {/* </div> */}
//       {/* </Grid> */}
//     </Grid>
//   </Paper>
// </Grid>