const dateHelper = {
  formatDate: (inputDateString: string): string => {
    const date = new Date(inputDateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0'); // Ensure two-digit hours
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two-digit minutes
    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }
};

export default dateHelper;
