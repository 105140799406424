import { createSlice } from "@reduxjs/toolkit";
import { trafficEventLogsApiSlice } from "./service/trafficEventLogsApiSlice";

interface initialStateI {
  isLoadingData: Boolean
}

const initialState: initialStateI = {
  isLoadingData: false
};

const trafficEventLogsSlice = createSlice({
    name: 'trafficEventLogs',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        trafficEventLogsApiSlice.endpoints.getTrafficEventLogsList.matchPending,
        state => {
          state.isLoadingData = true;
        }
      )
      .addMatcher(
        trafficEventLogsApiSlice.endpoints.getTrafficEventLogsList.matchFulfilled,
        state => {
          state.isLoadingData = false;
        }
      )
      .addMatcher(
        trafficEventLogsApiSlice.endpoints.getTrafficEventLogsList.matchRejected,
        state => {
          state.isLoadingData = false;
        }
      )
    }
})

export const trafficEventLogsReducer = trafficEventLogsSlice.reducer