import { createSlice } from "@reduxjs/toolkit";
import { areaTrackingApiSlice } from "./service/areaTrackingApiSlice";

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  isLoadingEvent: false
};

const areaTrackingSlice = createSlice({
    name: 'areaTracking',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        areaTrackingApiSlice.endpoints.createAreaTracking.matchFulfilled,
        state => {
          state.messageSuccess = 'Area Tracking Success Created';
        }
      )
      .addMatcher(
        areaTrackingApiSlice.endpoints.approvalDevice.matchPending,
        state => {
          state.isLoadingEvent = true;
        }
      )
      .addMatcher(
        areaTrackingApiSlice.endpoints.approvalDevice.matchFulfilled,
        state => {
          state.isLoadingEvent = false;
        }
      )
    }
})


// export const {} = areaTrackingSlice.actions;
export const areaTrackingReducer = areaTrackingSlice.reducer