import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L, { LatLngTuple, map } from "leaflet";
import "leaflet-routing-machine";
import { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import { createControlComponent } from "@react-leaflet/core";
import RoutineMachine from "./RoutineMachine";

const MapVehicleTracking = ({ data }: any) => {
  const [lines, setLines] = useState<any>([]);
  const [current, setCurrent] = useState<number | null>(null);
  const mapRef = useRef<L.Map | null>(null);

  const handleMapReady = () => {
    if (mapRef.current !== null) {
      initializeMap(mapRef.current);
    }
  };

  const initializeMap = (map: L.Map) => {
    mapRef.current = map;

    if (lines.length > 0) {
      const waypoints = lines.map((point: any) => L.latLng(point[0], point[1]));

      (L as any).Routing.control({
        waypoints: waypoints,
        routeWhileDragging: true,
      }).addTo(mapRef.current);
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      console.log("data hahaha ===> ", data);
      const arrLines: any = [];
      data.events.forEach((item: any) => {
        arrLines.push(item.position);
      });
      const scbdArrLines =  [
        { lat: 0.945122, lng: 104.078734 },
        { lat: 0.936755, lng: 104.097937 },
        { lat: 0.903664, lng: 104.127249 },
        { lat: 0.8725, lng: 104.151167 },
        { lat: 0.838944, lng: 104.175586 },
        { lat: 0.80225, lng: 104.183452 }
      ]
      setLines(scbdArrLines);
    } else {
      setLines([]);
    }
  }, [data]);

  useEffect(() => {
    if (mapRef.current !== null) {
      initializeMap(mapRef.current);
    }
  }, [lines]);

  const handleClosePopup = () => {
    setCurrent(null);
  };

  function MyComponent() {
    const map = useMapEvents({
      click: () => {
        map.locate();
      },
      locationfound: (location: any) => {
        console.log("location found:", location);
      },
    });
    return null;
  }

  function MyComponentMap() {
    const map = useMap();
    console.log("map center:", map.getCenter());
    return null;
  }

  const position = [51.505, -0.09]
  return (
    <div style={{ marginBottom: "30px" }}>
      <MapContainer
        // center={[0.863286, 104.159567]}
        center={[-6.2262125712941865, 106.8098350199563]}
        id="mapId"
        zoom={17}
        style={{ width: "100%", height: "920px" }}
        scrollWheelZoom={false}
        whenReady={handleMapReady} // Set the mapRef.current when the map is ready
      >
        {/* ... other components */}
        <MyComponent />
        <MyComponentMap />
        {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        /> */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {/* Polyline */}
        {/* <Polyline positions={lines} color="blue" weight={2.5} opacity={0.8} /> */}

        //TODO new updated below code to uncomment
        {/* {data?.events !== undefined ? (
          <RoutineMachine latleng={data?.events || []} />
        ) : null} */}
        //untill here for route the street
        {/* Markers and Popups */}
        {data?.events?.map((item: any, index: number) => (
          <Marker
            position={item.position}
            // eventHandlers={{
            //   click: () => {
            //     console.log('item ===> ', item, index)
            //     setCurrent(index);
            //   },
            // }}
          >
            {/* {current === index && ( */}
              <Popup position={item.position}>
                {/* <div>
                  <b> */}
                {item.timestamp} - {item.description}
                {/* </b>
                </div> */}
                {/* You can add any content for the Popup here */}
                {/* <button onClick={handleClosePopup}>Close</button> */}
              </Popup>
            {/* )} */}
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapVehicleTracking;
