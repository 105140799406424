import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const LogResultSearch = ({ params, setParams, firstOpen }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [plateNumber, setPlateNumber] = useState("");
  const [rfid, setRfid] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    plateNumber: string;
    rfid: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      plateNumber: "",
      rfid: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const handlePlateNumber = (e: string) => { setPlateNumber(e); };
  const handleRfid = (e: string) => { setRfid(e); };
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(dateEnd))) {
        setAllDate(NewDate);
      } else {
        setDateStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      plateNumber: plateNumber,
      rfid: rfid,
      dateStart: date,
      dateEnd: date,
    });
    setDateStart(date);
    setDateEnd(date);
  }
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setDateEnd(NewDate);
  };
  const conditionCheck = () => {
    if(dateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Date"
      });
      reset({
        plateNumber: plateNumber,
        rfid: rfid,
        dateStart: "",
        dateEnd: "",
      });
      setDateEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(plateNumber), [plateNumber], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(rfid), [rfid], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      plateNumber: plateNumber,
      rfid: rfid,
      dateFrom: dateStart,
      dateTo: dateEnd,
    });
  }, [searchFilterDebounced]);

  useEffect(() => {
    if(firstOpen) {
      reset({
        plateNumber: "B 5555 ABC"
      });
    }
  })

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="plateNumber"
          errors={errors}
          control={control}
          required
          placeholder="Plate Number"
          maxLength={100}
          isLoading={false}
          onChange={handlePlateNumber}
        />
        <InputForm
          name="rfid"
          errors={errors}
          control={control}
          required
          placeholder="RFID ID"
          maxLength={100}
          isLoading={false}
          onChange={handleRfid}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(dateStart).getTime())}
          onChange={handleDateEnd}
        />
      </Box>
    </Box>
  );
};

export default LogResultSearch;
