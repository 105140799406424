import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DnsIcon from '@mui/icons-material/Dns';
import DvrIcon from '@mui/icons-material/Dvr';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import MapIcon from '@mui/icons-material/Map';

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  },
  { 
    key: 'traffic-event-log',
    name: 'Traffic Event Log',
    path: '/traffic-event-log',
    icon: <DvrIcon />,
  },

  { 
    key: 'vehicle-tracking',
    name: 'Vehicle Tracking',
    path: '/vehicle-tracking',
    icon: <MyLocationIcon />,
  },
  { 
    key: 'area-tracking',
    name: 'Area Tracking',
    path: '/area-tracking',
    icon: <MapIcon />,
  },

  // { 
  //   key: 'license-server',
  //   name: 'License Server',
  //   path: '/license-server',
  //   icon: <DnsIcon />,
  // },
  // { 
  //   key: 'log',
  //   name: 'Log',
  //   path: '',
  //   icon: <DvrIcon />,
  //   children: [
  //     { 
  //       key: 'log-events',
  //       name: 'Log Events',
  //       path: '/log-events',
  //       icon: <DvrIcon />,
  //     },
  //     { 
  //       key: 'vehicle-tracking',
  //       name: 'Vehicle Tracking',
  //       path: '/vehicle-tracking',
  //       icon: <DvrIcon />,
  //     },
  //   ]
  // },
  // { 
  //   key: 'admin-management',
  //   name: 'Admin Management',
  //   path: '/admin-management',
  //   icon: <ManageAccountsIcon />,
  // },

];
