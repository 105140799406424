import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        // USER
        submitLogin: builder.mutation<any, any>({
            query: credentials => {
                const { Email, Password } = credentials;
                const pasEnc = encrypt(Password);
                return ({
                    url: '/login',
                    method: 'POST',
                    body: {
                        email: Email,
                        password: pasEnc,
                    },
                })
            },
            invalidatesTags:['submitLogin', 'GetProfileDetail']
        }),
        updateProfilePassword: builder.mutation<any, any>({
          query: ({ oldPassword, newPassword }) => {
            const encryptedOldPassword = encrypt(oldPassword);
            const encryptedNewPassword = encrypt(newPassword);
            return {
              url: `/user/change-password`,
              method: "PUT",
              body: {
                old_password: encryptedOldPassword,
                update_password: encryptedNewPassword
              }
            }
          },
          invalidatesTags: ['UpdateProfilePassword']
        }),
        getProfileDetail: builder.mutation<any, any>({
          query: () => ({
            url: `/user`,
            method: "GET"
          }),
          invalidatesTags: ['GetProfileDetail']
        }),

        // DASHBOARD
        getDetectedVehicle: builder.mutation<any, any>({
          query: () => ({
            url: `/home/header`,
            method: "GET"
          }),
          invalidatesTags: ['GetDetectedVehicle']
        }),
        getLicensePlateType: builder.mutation<any, any>({
          query: () => ({
            url: `/home/license/plate/type`,
            method: "GET"
          }),
          invalidatesTags: ['GetLicensePlateType']
        }),
        getVehicleCount: builder.query<any, any>({
          query: (params) => ({
            url: `/home/vehicle/count`,
            params: params,
          }),
        }),
        
    })
})

export const {
    useSubmitLoginMutation,
    useUpdateProfilePasswordMutation,
    useGetProfileDetailMutation,
    useGetDetectedVehicleMutation,
    useGetLicensePlateTypeMutation,
    useGetVehicleCountQuery,
} = userApiSlice