import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import AreaTrackingSearch from "../../components/molecules/areaTracking/AreaTrackingSearch";
import AreaTrackingTable from "../../components/molecules/areaTracking/AreaTrackingTable";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import MapAreaTracking from "../../components/molecules/maps/MapAreaTracking";
import { dataAreaTracking } from "../../utils/datajson/dataAreaTracking";

const AreaTracking = () => {
  
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<any>({});
  const [firstOpen, setFirstOpen] = useState(true);
  const [areas, setAreas] = useState<any>("All Area");
  const [defaultValue, setDefaultValue] = useState({});
  const [ currentData, setCurrentData ] = useState<any>({});

  useEffect(() => {
    if(firstOpen) {
      setFirstOpen(false)
      setParams({
        ...params,
        timeRange: "Daily"
      });
    }
  })

  useEffect(() => {
    let data = dataAreaTracking.find((item: any) => item.time_range == params.timeRange)
    setCurrentData(data)
  }, [params])


  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={false}
      isLoadingEvent={false}
      titleWidth={"300px"}>
      <Box sx={{ marginBottom: '36px' }} >

        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}>
          Area Tracking
        </Typography>

        <AreaTrackingSearch params={params} setParams={setParams} firstOpen={firstOpen} />

        <Grid container spacing={4}>

          <Grid item xs={6}>
            <MapAreaTracking
              data={currentData?.areas}
              totalVehicle={currentData?.total_vehicle}
              areas={areas}
              setAreas={setAreas}
            />
          </Grid>

          <Grid item xs={6}>
            <Paper elevation={24} sx={{ p: 4, flex: 1, border: "1px solid #ccc", marginY: "20px" }}>
              <Typography
                variant="h4"
                fontFamily="Open Sans">
                Total Vehicle - {areas} : {areas == "All Area" ? currentData?.total_vehicle : currentData?.areas?.find((fi:any) => fi.label == areas)?.vehicle_area}
              </Typography>
            </Paper>
            <Paper elevation={24} sx={{ p: 4, flex: 1, border: "1px solid #ccc", marginBottom: "20px" }}>
              <Typography
                variant="h3"
                fontFamily="Open Sans"
                sx={{ marginBottom: "20px" }}>
                License Plate Type - {areas}
              </Typography>
              <AreaTrackingTable
                data={currentData?.areas?.find((fi:any) => fi.label == areas) ? currentData?.areas?.find((fi:any) => fi.label == areas).license_plate : []}
                params={params}
                setParams={setParams}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
              />
            </Paper>
            <Paper elevation={24} sx={{ p: 4, flex: 1, border: "1px solid #ccc" }}>
              <Typography
                variant="h3"
                fontFamily="Open Sans"
                sx={{ marginBottom: "20px" }}>
                Vehicle Type - {areas}
              </Typography>
              <AreaTrackingTable
                data={currentData?.areas?.find((fi:any) => fi.label == areas) ? currentData?.areas?.find((fi:any) => fi.label == areas).vehicle_type : []}
                params={params}
                setParams={setParams}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
              />
            </Paper>
          </Grid>

        </Grid>

      </Box>
    </ListDataSkeleton>
  );
};

export default AreaTracking;
