export const dataVehicleTracking = [
    {
        plateNumber: "B 1111 ABC",
        plateType: "Private (Black)",
        vehicleType: "Sedan / Pick Up",
        vehicleColor: "Silver White",
        rfid: "4F422275334835423F532C35",
        events: [
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "In - Rempang Island",
                image: "https://i.ibb.co/qDPts8w/Microsoft-Teams-image-2.png",
                position: { lat: 0.945122, lng: 104.078734 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 01",
                image: "https://i.ibb.co/qDPts8w/Microsoft-Teams-image-2.png",
                position: { lat: 0.936755, lng: 104.097937 }
            }
        ]
    },
    {
        plateNumber: "B 2222 ABC",
        plateType: "Public (Yellow)",
        vehicleType: "Motorcycle",
        vehicleColor: "Red Magenta",
        rfid: "5F452C4F35F55F452C4F35F5",
        events: [
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "In - Rempang Island",
                image: "https://i.ibb.co/7R42dV2/Microsoft-Teams-image-1.png",
                position: { lat: 0.945122, lng: 104.078734 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 01",
                image: "https://i.ibb.co/7R42dV2/Microsoft-Teams-image-1.png",
                position: { lat: 0.936755, lng: 104.097937 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 02",
                image: "https://i.ibb.co/7R42dV2/Microsoft-Teams-image-1.png",
                position: { lat: 0.903664, lng: 104.127249 }
            }
        ]
    },
    {
        plateNumber: "B 3333 ABC",
        plateType: "Government (Red)",
        vehicleType: "Minibus/Hatchback/City Car",
        vehicleColor: "Metalic Grey",
        rfid: "5F324F2C5F4C5F324F2C5F4C",
        events: [
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "In - Rempang Island",
                image: "https://i.ibb.co/VxnHnKW/desktop-wallpaper-auto-acura-cars-city-lights-asphalt-lanterns-side-view-style-akura-street-tl-metal.jpg",
                position: { lat: 0.945122, lng: 104.078734 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 01",
                image: "https://i.ibb.co/VxnHnKW/desktop-wallpaper-auto-acura-cars-city-lights-asphalt-lanterns-side-view-style-akura-street-tl-metal.jpg",
                position: { lat: 0.936755, lng: 104.097937 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 02",
                image: "https://i.ibb.co/VxnHnKW/desktop-wallpaper-auto-acura-cars-city-lights-asphalt-lanterns-side-view-style-akura-street-tl-metal.jpg",
                position: { lat: 0.903664, lng: 104.127249 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 03",
                image: "https://i.ibb.co/VxnHnKW/desktop-wallpaper-auto-acura-cars-city-lights-asphalt-lanterns-side-view-style-akura-street-tl-metal.jpg",
                position: { lat: 0.872500, lng: 104.151167 }
            }
        ]
    },
    {
        plateNumber: "B 4444 ABC",
        plateType: "Free Trade (Green)",
        vehicleType: "Truk",
        vehicleColor: "Yellow",
        rfid: "4C355243524F4C355243524F",
        events: [
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "In - Rempang Island",
                image: "https://i.ibb.co/FxbRQQN/Microsoft-Teams-image.png",
                position: { lat: 0.945122, lng: 104.078734 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 01",
                image: "https://i.ibb.co/FxbRQQN/Microsoft-Teams-image.png",
                position: { lat: 0.936755, lng: 104.097937 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 02",
                image: "https://i.ibb.co/FxbRQQN/Microsoft-Teams-image.png",
                position: { lat: 0.903664, lng: 104.127249 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 03",
                image: "https://i.ibb.co/FxbRQQN/Microsoft-Teams-image.png",
                position: { lat: 0.872500, lng: 104.151167 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 04",
                image: "https://i.ibb.co/FxbRQQN/Microsoft-Teams-image.png",
                position: { lat: 0.838944, lng: 104.175586 }
            }
        ]
    },
    {
        plateNumber: "B 5555 ABC",
        plateType: "Corps Diplomatic (White)",
        vehicleType: "Jeep - SUV",
        vehicleColor: "Black",
        rfid: "3525F5C3F2F43525F5C3F2F4",
        events: [
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "In - Rempang Island",
                image: "https://i.ibb.co/Df8Ck7Z/images.jpg",
                position: { lat: 0.945122, lng: 104.078734 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 01",
                image: "https://i.ibb.co/Df8Ck7Z/images.jpg",
                position: { lat: 0.936755, lng: 104.097937 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 02",
                image: "https://i.ibb.co/Df8Ck7Z/images.jpg",
                position: { lat: 0.903664, lng: 104.127249 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 03",
                image: "https://i.ibb.co/Df8Ck7Z/images.jpg",
                position: { lat: 0.872500, lng: 104.151167 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 04",
                image: "https://i.ibb.co/Df8Ck7Z/images.jpg",
                position: { lat: 0.838944, lng: 104.175586 }
            },
            {
                timestamp: "2023-06-23T17:31:34+07:00",
                description: "Seen at Area 05",
                image: "https://i.ibb.co/Df8Ck7Z/images.jpg",
                position: { lat: 0.802250, lng: 104.183452 }
            }
        ]
    }
]