// import { Button, IconButton, Typography } from '@mui/material';
// import { GoogleMap, InfoWindow, LoadScript, Marker, Polygon } from '@react-google-maps/api';
// import { useState } from 'react';
// import CloseIcon from "@mui/icons-material/Close";

// const MapAreaTracking = ({ data, totalVehicle, areas, setAreas }: any) => {

//   const rempangIslandCoordinate = {
//       lat: 0.863286,
//       lng: 104.159567
//   };

//   const [activeMarker, setActiveMarker] = useState(0);
//   const handleActiveMarker = (marker: number) => {
//     setActiveMarker(marker);
//   };
  
//   return (
//     <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'center' }}>
//       {
//         areas != "All Area" && <Button
//         onClick={() => {
//           setActiveMarker(0)
//           setAreas('All Area')
//         }}
//         variant={'contained'}
//         style={{ borderRadius: '24px', backgroundColor: 'white', color: 'black', position: 'absolute', zIndex: 1, marginTop: '24px' }}>
//           <Typography style={{ fontWeight: 'bold', marginRight: '8px'}}>Clear Selection</Typography>
//           <IconButton sx={{ backgroundColor: 'transparent' }}>
//             <CloseIcon style={{ color: 'black' }} />
//           </IconButton>
//         </Button>
//       }

//       <LoadScript googleMapsApiKey={""}>
//         <GoogleMap
//           mapContainerStyle={{
//             width: '100%',
//             height: '1110px',
//           }}
//           center={rempangIslandCoordinate}
//           zoom={12}>

//           {
//             data?.filter((item:any) => item.label != "All Area" ).map((item: any, index: number) => {
//               const id = index + 1;
//               return <>
//               <Polygon
//                 paths={item.area_polygon}
//                 options={{
//                   fillColor: (activeMarker == id || activeMarker == 0) ? 'red' : 'green',
//                   fillOpacity: item.vehicle_area/totalVehicle,
//                   strokeWeight: 1,
//                   clickable: true,
//                   draggable: false,
//                   editable: false,
//                   geodesic: true,
//                   zIndex: 1,
//                 }}
//                 onClick={() => {
//                   handleActiveMarker(id)
//                   setAreas(item.label)
//                 }}
//               />
//               { activeMarker == id &&
//               <Marker
//                   key={id}
//                   position={item.marker_position}
//                   children={
//                     <InfoWindow
//                       position={item.info_position}
//                       onCloseClick={() => {
//                         setActiveMarker(0)
//                         setAreas('All Area')
//                       }}
//                       children={<div><b>{item.label} ({item.vehicle_area} Vechicle)</b></div>}
//                     />
//                   }
//                 />
//               }
//               </>;
//             })
//           }

//         </GoogleMap>
//       </LoadScript>
//     </div>
//   );
// }

// export default MapAreaTracking;


import { MapContainer, TileLayer, Polygon, Marker, Popup } from 'react-leaflet';
import { useState } from 'react';
import 'leaflet/dist/leaflet.css'; 

interface MapArea {
  label: string;
  vehicle_area: number;
  area_polygon: [number, number][];
  marker_position: [number, number];
}

interface MapAreaTrackingProps {
  data: MapArea[] | null;
  totalVehicle: number;
  areas: string;
  setAreas: (area: string) => void;
}

const MapAreaTracking: React.FC<MapAreaTrackingProps> = ({ data, totalVehicle, areas, setAreas }) => {
  const rempangIslandCoordinate = {
    // rempang coordinate
    lat: 0.863286,
    lng: 104.159567
    // scbd coordinate
    // lat: 6.1944,
    // lng: 106.8229
  };

  const [activeMarker, setActiveMarker] = useState<number>(0);

  const handleActiveMarker = (marker: number) => {
    setActiveMarker(marker);
  };

  return (
    <div style={{ marginBottom: '30px', height: '1110px' }}>
      {areas !== 'All Area' && (
        <button
          onClick={() => {
            setActiveMarker(0);
            setAreas('All Area');
          }}
          style={{
            borderRadius: '24px',
            backgroundColor: 'white',
            color: 'black',
            position: 'absolute',
            zIndex: 1,
            marginTop: '24px'
          }}
        >
          Clear Selection
        </button>
      )}

      <MapContainer
        // center={[rempangIslandCoordinate.lat, rempangIslandCoordinate.lng]}
        center={[-6.2262125712941865, 106.8098350199563]}
        zoom={17}
        style={{ width: '100%', height: '100%' }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        
        //TODO code below will updat when need marker red or green, please don't delete it
        {/* {data
          ?.filter((item) => item.label !== 'All Area')
          .map((item, index) => {
            const id = index + 1;
            return (
              <div key={id}>
                <Polygon
                  positions={item.area_polygon}
                  pathOptions={{
                    fillColor: activeMarker === id || activeMarker === 0 ? 'red' : 'green',
                    fillOpacity: item.vehicle_area / totalVehicle,
                    weight: 1,
                    color: 'black',
                    opacity: 1
                  }}
                  eventHandlers={{
                    click: () => {
                      handleActiveMarker(id);
                      setAreas(item.label);
                    }
                  }}
                />
                {activeMarker === id && (
                  <Marker position={item.marker_position}>
                    <Popup>
                      <div>
                        <b>
                          {item.label} ({item.vehicle_area} Vehicle)
                        </b>
                      </div>
                    </Popup>
                  </Marker>
                )}
              </div>
            );
          })} */}
      </MapContainer>
    </div>
  );
};

export default MapAreaTracking;