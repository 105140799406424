
import CryptoJS from 'crypto-js';

export const encrypt = (data: string) => {
    const key = 'DOPSjDlaUnj2NCytiFHhsVTchHzN4t8D';
    const iv = 'aWXvEh2EdcqwHSmD';
    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    });
  
    return cipher.toString();
  };