import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, CircularProgress, Container, Skeleton } from '@mui/material';
import { useGetLicensePlateTypeMutation } from '../../../store/feature/service/userApiSlice';
import { useEffect, useState } from 'react';

const DashTable = ({dataGrid, title}: any) => {

  const [data, setData] = useState([]);
  const [getPlateType, { data: dataPlateType, isLoading }] = useGetLicensePlateTypeMutation();

  useEffect(()=> {
    getPlateType({})
  }, [])

  useEffect(()=> {
    const plates = dataPlateType?.data
    if(plates != undefined) {
      const arrData: any = Object.entries(plates)
      .map(([key, value]) => ({
        type: key,
        count: value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      }))
      setData(arrData);
    }

  }, [dataPlateType])

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: `${title} Type`,
      flex: 1,
      minWidth: 275,
    },
    {
      field: "count",
      headerName: "Vehicle Count",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "rfid",
      headerName: "RFID Count",
      flex: 1,
      minWidth: 50,
    },
  ]

  const arrGrid = data !== undefined || null ? data : [] 
  const rows = arrGrid?.map((row: any, index: number) => ({
    id: index,
    type: row.type,
    count: row.count,
    rfid: row.rfid,
  }));

  return (
    <Box sx={{ height: "400px" }}>
        {
          !isLoading ? <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
        : <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'100%'} height={'100%'}/>
        }
      </Box>
  );
};

export default DashTable;
